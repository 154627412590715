import { useRouter } from 'next/router'
import { useEffect } from 'react'

const GettingUserPage = () => {
  const { push } = useRouter()
  useEffect(() => {
    push('/report/new')
  })
  return <p>Redirecting...</p>
}

export default GettingUserPage
